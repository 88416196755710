import { useEffect } from "react";
import { useState } from "react";
import "./Carousel.css";
import Slide1 from "../../images/Slides/slide1.png";
import Slide2 from "../../images/Slides/slide2.png";
import Slide3 from "../../images/Slides/slide3.png";
import Slide4 from "../../images/Slides/slide4.png";
const data = [
  {
    id: "c1",
    image: Slide1,
  },
  {
    id: "c2",
    image: Slide2,
  },
  {
    id: "c3",
    image: Slide3,
  },
  {
    id: "c4",
    image: Slide4,
  },
  /* {
    id: "c4",
    image:
      "https://cdn-media.buildersmart.in/media/blog/Building-Materials.jpg",
  }, */
];

function Carousel() {
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 5000);
  });

  const slideRight = () => {
    setCurrent(current === data.length - 1 ? 0 : current + 1);
  };

  const slideLeft = () => {
    setCurrent(current === 0 ? data.length - 1 : current - 1);
  };
  //console.log(current);
  return (
    <div
      className="carousel"
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <div className="carousel_wrapper">
        {data.map((image, index) => {
          return (
            /* (condition) ? true : false */

            <div
              key={index}
              className={
                index === current
                  ? "carousel_card carousel_card-active"
                  : "carousel_card"
              }
            >
              <img className="card_image" src={image.image} alt="" />
              <div className="card_overlay">
                {/* <h2 className="card_title">{image.title}</h2> */}
              </div>
            </div>
          );
        })}
        <div className="carousel_arrow_left" onClick={slideLeft}>
          &lsaquo;
        </div>
        <div className="carousel_arrow_right" onClick={slideRight}>
          &rsaquo;
        </div>
        <div className="carousel_pagination">
          {data.map((_, index) => {
            return (
              <div
                key={index}
                className={
                  index === current
                    ? "pagination_dot pagination_dot-active"
                    : "pagination_dot"
                }
                onClick={() => setCurrent(index)}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Carousel;
