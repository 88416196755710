import React from "react";
import styles from './CardBackground.module.css';

const CardBackground = (props) => {

    return (      
            <div className={styles.card} onClick={props.onClick} >
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>                

    )
}

export default CardBackground;

