import { React, useRef, useState, useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUsPage from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import ServicesPage from "./Pages/ServicesPage";
import ClientsPage from "./Pages/ClientsPage";
import HomePage from "./Pages/HomePage";
import { BsArrowUpSquareFill } from "react-icons/bs";

import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Carousel from "./components/slider/Carousel";

// Debounce utility function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function Layout() {
  const [isVisible, setIsVisible] = useState(false);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const clientRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    const offset = 120; // Adjust this value to the height of your nav bar
    const elementPosition = sectionRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  // Debounce the scrollToSection function to prevent rapid multiple triggers
  const debouncedScrollToSection = debounce(scrollToSection, 300);

  const sectionRefs = {
    aboutRef,
    servicesRef,
    clientRef,
    contactRef,
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Show button when page is scrolled down a certain amount
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      // Adjust 300 to your preference
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add/remove event listener on scroll
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Navbar
          scrollToSection={debouncedScrollToSection}
          sectionRefs={sectionRefs}
        />

        <main>
          <Carousel />
          <Routes>
            <Route path="/" element={<HomePage sectionRefs={sectionRefs} />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/clients" element={<ClientsPage />} />
          </Routes>

          {isVisible && (
            <BsArrowUpSquareFill
              onClick={scrollToTop}
              size={60}
              color="#3986BE"
              style={{
                position: "fixed",
                bottom: "20",
                right: "20",
                padding: "10",
                cursor: "pointer",
              }}
            />
          )}
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default Layout;
