import React from "react";
import Clients from "../components/Clients/Clients";

function ClientsPage() {
  return (
    <div>
      <Clients />
    </div>
  );
}

export default ClientsPage;
