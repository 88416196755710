import React from "react";
import ContactForm from "../components/Contact/ContactForm";

function ContactUsPage() {
  return (
    <div>
      <ContactForm />
    </div>
  );
}

export default ContactUsPage;
