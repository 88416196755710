import React from "react";
import styles from "./Services.module.css";
import service1 from "../../images/services/service1.png";
import service2 from "../../images/services/service2.png";
import service3 from "../../images/services/service3.png";
import service4 from "../../images/services/service4.png";
import service5 from "../../images/services/service5.png";
import service6 from "../../images/services/service6.png";

const Services = () => {
  return (
    <div className={styles.luxury}>
      <div className={styles.container}>
        <div className={styles.card}>
          <img src={service1} alt="example" />

          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <h5>AI/ML & Gen AI </h5>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src={service2} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <h5>Data Governance</h5>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src={service5} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <h5>RPA</h5>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>

        <div className={styles.card}>
          <img src={service4} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <h5>Data Analytics </h5>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>

        <div className={styles.card}>
          <img src={service6} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <h5>Migration</h5>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img src={service3} alt="example" />
          <div className={styles.content}>
            <div style={{ marginBottom: 20 }}>
              <h5>Data Integration</h5>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the text ever since the
              1500s,......
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
