import React from "react";
import LogoWhite from "../../images/logoWhite.png";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div style={{ margin: "10" }}>
          <div>
            <img src={LogoWhite} alt="" className={styles.logoImage} />
          </div>
          <div className={styles.iconContainer}>
            <a>
              <FaFacebookSquare size={20} />
            </a>
            <a>
              <FaLinkedin size={20} />
            </a>
            <a>
              <FaInstagramSquare size={20} />
            </a>
            <a>
              <FaTwitterSquare size={20} />
            </a>
            <a>
              <IoLogoYoutube size={20} />
            </a>
          </div>
          <div style={{ marginTop: 10 }} className={styles.last}>
            <p>
              Copyright @ 2024, Dattamsa. All Rights Reserved | Privacy Policy |
              Terms of Use
            </p>
          </div>
        </div>
        <div style={{ margin: 15 }}>
          <h2>Quick Links</h2>
          <ul>
            <li>
              <Link to={"/"}>About Us</Link>
            </li>
            <li>
              <Link to={"/"}>Services</Link>
            </li>
            <li>
              <Link to={"/"}>Our Clients</Link>
            </li>
            <li>
              <Link to={"/"}>Contact Us</Link>
            </li>
          </ul>
        </div>
        <div style={{ margin: 15 }}>
          <h2>Services</h2>
          <ul>
            <li>
              <a href="/#">AI/ML & Gen AI </a>
            </li>
            <li>
              <a href="/#">Data Governance</a>
            </li>
            <li>
              <a href="/#">RPA</a>
            </li>
            <li>
              <a href="/#">Data Analytics</a>
            </li>
            <li>
              <a href="/#">Migration</a>
            </li>
            <li>
              <a href="/#">Data Integration</a>
            </li>
          </ul>
        </div>
        <div>
          <div style={{ margin: 15 }}>
            <ul>
              <li>
                <h4> contactus@Dattamsa.org</h4>
              </li>
              <li>
                <h4>surya.jaipal@Dattamsa.org</h4>
              </li>
              <li>
                <h4>+91-9052247773</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
