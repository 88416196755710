import React from "react";
import styles from "./Clients.module.css";

import client1 from "../../images/client/client1.png";
import client2 from "../../images/client/client2.png";
import client3 from "../../images/client/client3.png";
import client4 from "../../images/client/client4.png";
import client5 from "../../images/client/client5.png";
import client6 from "../../images/client/client6.png";
import client7 from "../../images/client/client7.png";
import client8 from "../../images/client/client8.png";
import client9 from "../../images/client/client9.png";

const List = [
  {
    id: "0",
    image: client1,
  },
  {
    id: "1",
    image: client2,
  },
  {
    id: "2",
    image: client3,
  },
  {
    id: "3",
    image: client4,
  },
  {
    id: "4",
    image: client5,
  },
  {
    id: "5",
    image: client6,
  },
  {
    id: "6",
    image: client7,
  },

  {
    id: "8",
    image: client8,
  },
  {
    id: "9",
    image: client9,
  },
];

function Clients() {
  return (
    <div className={styles.luxury}>
      <h3> Our Clients </h3>

      <div className={styles.container}>
        {List.map((item) => (
          <div className={styles.card} key={item.id}>
            <div className={styles.content}>
              <img src={item.image} alt={item.title} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Clients;
