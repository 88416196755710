import React from "react";
import About from "../components/About/About";

function AboutUsPage() {
  return (
    <div>
      <About />
    </div>
  );
}

export default AboutUsPage;
